<script>
  import { onMount } from "svelte";
  var myDiagram;
  onMount(() => {
    myDiagram = new go.Diagram(
      "myDiagramDiv", // create a Diagram for the HTML Div element
      { "undoManager.isEnabled": true }
    ); // enable undo & redo

    // define a simple Node template
    myDiagram.nodeTemplate = new go.Node("Auto") // the Shape will go around the TextBlock
      .add(
        new go.Shape("RoundedRectangle", { strokeWidth: 0, fill: "white" }) // no border; default fill is white
          .bind("fill", "color")
      ) // Shape.fill is bound to Node.data.color
      .add(
        new go.TextBlock({
          margin: 8,
          font: "bold 14px sans-serif",
          stroke: "#333",
        }) // some room around the text
          .bind("text", "key")
      ); // TextBlock.text is bound to Node.data.key

    // but use the default Link template, by not setting Diagram.linkTemplate

    // create the model data that will be represented by Nodes and Links
    myDiagram.model = new go.GraphLinksModel(
      [{ key: "Protocolo de prueba", color: "lightblue" }],
      []
    );
    myDiagram.layout = go.GraphObject.make(go.LayeredDigraphLayout, {
      direction: 0, // Cambia la dirección del diseño si es necesario
      layerSpacing: 50, // Espacio entre las capas del diagrama
      columnSpacing: 20, // Espacio entre las columnas del diagrama
    });
    myDiagram.layoutDiagram(true); // do an initial layout of the diagram
  });
  let protocol = {
    name: "Protocolo de prueba",
    init: "0",
  };
  const paso_base = {
    mensaje: "Mensaje para el paso",
    actions: ["", "", ""],
    options: ["Seccion 1", "", ""],
    function: ["", "", ""],
    btn_color: ["success", "success", "success"],
    btn_size: ["lg", "lg", "lg"],
    run: "",
    init: "",
  };
  let protocol_t;
  let protocol_text = "";
  let functions = [
    "close_alert",
    "close_alert_notif",
    "call_oper",
    "call_matriz",
    "msg_oper",
    "security_command",
    "security_off",
    "pause_alert",
  ];
  function generate() {
    protocol_text = JSON.stringify(protocol, null, 2);
    if (protocol_t) autoExpand(protocol_t);
  }
  let protocol_list = [];
  function get_protocol() {
    let list = [];
    for (let x in protocol) {
      if (x != "name" && x != "init") {
        list.push(x);
      }
    }
    protocol_list = list;
  }
  function add_step() {
    let paso_base_ = JSON.parse(JSON.stringify(paso_base));
    if (protocol_list.length == 0) {
      protocol["0"] = paso_base_;
    } else {
      let obj = Math.max(...protocol_list) + 1;
      paso_base_.mensaje = paso_base_.mensaje + obj;
      protocol[obj] = paso_base_;
    }
    setTimeout(() => {
      autoExpand(document.querySelector("textarea"));
    }, 100);
  }
  function reverse() {
    try {
      //protocol = JSON.parse(protocol_text);
    } catch (e) {
      console.log(e);
    }
  }
  function update_graph() {
    let proto_g = [];
    let proto_d = [];
    for (let x in protocol_list) {
      proto_g.push({
        key: protocol[protocol_list[x]].mensaje,
        color: "lightblue",
      });
    }
    console.log(proto_g);
    for (let x in protocol_list) {
      for (let y in protocol[protocol_list[x]].actions) {
        if (protocol[protocol_list[x]].actions[y] != "") {
          try {
            proto_d.push({
              to: protocol[protocol[protocol_list[x]].actions[y]].mensaje,
              from: protocol[protocol_list[x]].mensaje,
              text: protocol[protocol_list[x]].options[y],
            });
          } catch (e) {}
        }
      }
    }
    console.log(proto_d);
    if (myDiagram) {
      myDiagram.model = new go.GraphLinksModel(proto_g, proto_d);
    }
  }
  function autoExpand(textarea) {
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  }
  $: {
    protocol;
    generate();
    get_protocol();
    update_graph();
  }

  // Grafica
</script>

<div class="container">
  <div class="row">
    <div class="col-12">
      <h1>Generador de protocolos Vector</h1>
    </div>
    <div class="col-6">
      <span>Nombre del protocolo</span>
      <input type="text" bind:value={protocol.name} class="form-control" />
    </div>
    <div class="col-6">
      <span>Paso Inicial</span>
      <input type="text" bind:value={protocol.init} class="form-control" />
    </div>
  </div>
  <div class="row">
    {#each protocol_list as p, i}
      <div class="col-12 mt-4">
        <div class="d-flex">
          <h4>Paso {p}</h4>
          <button
            class="btn btn-sm btn-danger ms-3"
            on:click={() => {
              delete protocol[p];
              protocol = protocol;
              setTimeout(() => {
                autoExpand(document.querySelector("textarea"));
              }, 100);
            }}>Eliminar</button
          >
        </div>
      </div>
      <div class="col-6">
        <span>Mensaje</span><input
          type="text"
          bind:value={protocol[p].mensaje}
          class="form-control"
        />
      </div>
      <div class="col-3">
        <span>Autoiniciar</span><select
          class="form-select"
          on:change={(e) => {
            protocol[p].run =
              Number(e.target.value) > -1 ? Number(e.target.value) : "";
          }}
        >
          <option selected value="-1"></option>
          <option value="0">Opcion 1</option>
          <option value="1">Opcion 2</option>
          <option value="2">Opcion 3</option>
        </select>
      </div>
      <div class="col-3">
        <span>Resumir llamada</span><select
          class="form-select"
          on:change={(e) => {
            protocol[p].init = e.target.value;
          }}
        >
          <option selected value=""></option>
          <option value="resume_call_oper">resume_call_oper</option>
          <option value="resume_call_matriz">resume_call_matriz</option>
        </select>
      </div>
      {#each [1, 2, 3] as s, j}
        <div class="col-12 mt-3">
          <h6>Opcion {s}</h6>
        </div>
        <div class="col-4">
          <span>Texto</span><input
            type="text"
            bind:value={protocol[p].options[j]}
            class="form-control"
          />
        </div>
        <div class="col-2">
          <span>Destino</span><select
            class="form-select"
            on:change={(e) => {
              protocol[p].actions[j] = e.target.value;
            }}
          >
            <option value=""></option>
            {#each protocol_list as pl}
              <option value={pl}>{pl}</option>
            {/each}
          </select>
        </div>
        <div class="col-2">
          <span>Funcion</span>
          <select
            class="form-select"
            on:change={(e) => {
              protocol[p].function[j] = e.target.value;
            }}
          >
            <option value=""></option>
            {#each functions as pl}
              <option value={pl}>{pl}</option>
            {/each}
          </select>
        </div>
        <div class="col-2">
          <span>Color</span><select
            class="form-select"
            on:change={(e) => {
              protocol[p].btn_color[j] = e.target.value;
            }}
          >
            <option selected value="success">Verde</option>
            <option value="danger">Rojo</option>
            <option value="warning">Amarillo</option>
          </select>
        </div>
        <div class="col-2">
          <span>Tamaño</span><select
            class="form-select"
            on:change={(e) => {
              protocol[p].btn_size[j] = e.target.value;
            }}
          >
            <option selected value="lg">Grande</option>
            <option value="">Normal</option>
            <option value="sm">Chico</option>
          </select>
        </div>
      {/each}
    {/each}
  </div>
  <div class="row">
    <div class="col-12 mt-4" style="text-align: end;">
      <button class="btn btn-primary" on:click={add_step}>
        Agregar paso
      </button>
    </div>
  </div>
  <div class="row">
    <div
      id="myDiagramDiv"
      style="
    border: 0px solid black;
    width: 100%;
    height: 400px;
    position: relative;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    cursor: auto;
  "
    >
      <canvas
        tabindex="0"
        width="398"
        height="398"
        style="
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      user-select: none;
      touch-action: none;
      width: 100%;
      height: 398px;
      cursor: auto;
    "
      ></canvas>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mt-1">
      <h3>Protocolo</h3>
    </div>
    <div class="col-12">
      <textarea
        bind:value={protocol_text}
        on:change={reverse}
        class="form-control"
        bind:this={protocol_t}
      />
    </div>
  </div>
</div>

<style>
  .container {
    margin-top: 50px;
  }
  textarea {
    min-height: 200px;
    resize: none;
    overflow: hidden;
  }
</style>
